import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchCheckDocument(params) {
    return request({
      url: baseURL + '/checkDocument/list',
      method: 'GET',
      params: params
    })
  }

export function fetchCheckDocumentDetail(param) {
  return request({
    url: baseURL + '/checkDocument/detail',
    method: 'GET',
    params: param
  })
}

export function saveData(data) {
  return request({
    url: baseURL + '/checkDocument/save',
    method: 'POST',
    data
  })
}